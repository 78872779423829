<template>
    <div>
        <b-card>
            <b-card-header header-class="pt-0">
                <h4>تفاصيل الوحدة {{ DetailsUnit.name }}</h4>
            </b-card-header>
            <b-card-body>
                <ValidationObserver ref="form">
                    <b-row>
                        <b-col lg="4">
                            <ek-input-text
                                name="name"
                                label=" اسم الجامعة "
                                placeholder="ادخل اسم الجامعة"
                                v-model="DetailsUnit.universityName"
                            >
                            </ek-input-text>
                            <b-row no-gutters>
                                <b-col lg="6" md="12">
                                    <ek-input-select
                                        :options="years"
                                        v-model="DetailsUnit.yearName"
                                        placeholder="السنة"
                                        name=" اختر السنة"
                                        label="السنة"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'السنة مطلوبة',
                                            },
                                        ]"
                                    >
                                    </ek-input-select>
                                </b-col>
                                <b-col lg="6" md="12" class="pl-2">
                                    <ek-input-select
                                        :options="semesters"
                                        placeholder="اختر الفصل"
                                        name=" اختر الفصل"
                                        label="الفصل"
                                        v-model="DetailsUnit.semesterName"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'الفصل مطلوب',
                                            },
                                        ]"
                                    >
                                    </ek-input-select>
                                </b-col>
                            </b-row>
                         

                            <ek-input-text
                                readonly
                                type="number"
                                name="name"
                                label=" عدد الاقسام "
                                placeholder="عدد الاقسام"
                                v-model="DetailsUnit.chaptersCount"
                            >
                            </ek-input-text>
                        </b-col>

                        <b-col lg="4">
                            <ek-input-text
                                :rules="[
                                    {
                                        type: 'required',
                                        message: '  يرجى اختيار الكلية',
                                    },
                                ]"
                                name="classes"
                                v-model="DetailsUnit.facultyName"
                                placeholder="تابع الكلية"
                                label="الكلية"
                                clearable
                            ></ek-input-text>
                            <b-row no-gutters>
                                <b-col lg="6" md="12" class="pl-2">
                                    <ek-input-select
                                        :options="semesters"
                                        placeholder="اسم المادة"
                                        name=" اسم المادة"
                                        label="اسم المادة"
                                        v-model="DetailsUnit.subjectName"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'اسم المادة مطلوب',
                                            },
                                        ]"
                                    >
                                    </ek-input-select>
                                </b-col>
                                <b-col lg="6" md="12" class="pl-2">
                                    <ek-input-text
                                        placeholder="اسم الاستاذ"
                                        name=" اسم الاستاذ"
                                        label=" اسم الاستاذ"
                                        v-model="DetailsUnit.tetcherName"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'اسم الاستاذ مطلوب',
                                            },
                                        ]"
                                    >
                                    </ek-input-text>
                                </b-col>
                            </b-row>
                            <ek-input-text
                            name="  ترتيب الوحدة"
                            label="ترتيب الوحدة"
                            ref="order"
                            type="number"
                            v-model="DetailsUnit.order"
                            placeholder=" ادخل الترتيب"
                        ></ek-input-text>
                        </b-col>
                        <b-col lg="4" class="position-relative">
                            <b-dropdown
                                size="lg"
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                class="position-absolute"
                            >
                                <template #button-content>
                                    <unicon
                                        name="ellipsis-v"
                                        fill="#2EC4B6"
                                    ></unicon>
                                </template>
                                <b-dropdown-item
                                    href="#"
                                    ref="myUploader"
                                    @click="fileWindowApi(onUpload)"
                                >
                                    <unicon
                                        name="edit-alt"
                                        fill="#2EC4B6"
                                        style="width: 25px"
                                    ></unicon>
                                    <span>تعديل الصورة</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <img
                                class="h-100 w-100 rounded"
                                style="object-fit: cover"
                                :src="
                                    $store.getters['app/domainHost'] +
                                    '/' +
                                    DetailsUnit.imageUrl
                                "
                                alt=""
                            />
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </b-card-body>
        </b-card>

        <ek-table
            :columns="columns"
            :items="DetailsUnit.chapters"
            @details="detailsCourse"

            :no_select="true"
            :no_delete="true"
        >
            <template #table-header>
                <div class="d-flex p-1">
                    <unicon class="pr-1" fill="gray" name="bookmark"></unicon>
                    <h4>أقسام الوحدة</h4>
                </div>
            </template>

            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : "_" }}
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { fileWindowApi } from "@core/utils/uploader";

export default {
    props: {
        id: String,
    },

    data: () => ({
        columns: [
            {
                label: "اسم القسم ",
                field: "name",
            },
            {
                label: "عدد الدروس ",
                field: "lessonCount",
            },

            {
                label: "عدد المشتركين",
                field: "studentCount",
            },
            {
                label: "تاريخ الاضافة",
                field: "dateCreated",
            },
            {
                label: "تفاصيل",
                field: "details",
            },
        ],
    }),
    computed: {
        ...mapState({
            DetailsUnit: (state) => state.unit.DetailsUnit,
            universityNames: (state) => state.university.universityName,
            years: (state) => state.settings.yearList,
            semesters: (state) => state.settings.semesterList,
        }),
    },
    methods: {
        ...mapActions([
            "getUnitDetails",
            "updateSubject",
            "deleteSubject",
            "getNamesUniversity",
            "getSettingSemester",
            "getSettingYear",
            "addFileUnit",
        ]),
        fileWindowApi,
        detailsCourse({ row }) {
            this.$router.push(`/admin/chapter/${row.id}`);
        },
        onUpload(e) {
            if (e.file) {
                this.addFileUnit({ ...e, folderName: "Profile" }).then((res) => {
                    this.DetailsUnit.imageUrl = res[0].data;
                    console.log(this.DetailsUnit.imageUrl, res[0].data);
                });
            }
        },
    },
    // beforeDestroy() {
    //   this.$store.commit("Set_Subjects_Dto");
    // },
    created() {
        this.getUnitDetails(this.id);
        this.getNamesUniversity("0");
        this.getSettingYear();
        this.getSettingSemester();
    },
};
</script>
<style lang="scss">
.dropdown.b-dropdown {
    right: 0 !important;
}
</style>
